
.time-picker {
    position: relative;
    display: inline-block;
    width: 75px;
}

.time-picker .tmk-wrapper {
    position: absolute;
    top: 38px;
    right: 0;
    z-index: 9999;
}

.cal-wrapper {
    height: 768px;
}

.react-datepicker-wrapper {
    width: 125px;
}
